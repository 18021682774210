/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_cognito_identity_pool_id": "ap-southeast-1:b53b019c-7fd7-460b-b17e-257903e5635e",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_mymwm9b2R",
    "aws_user_pools_web_client_id": "1godm02k7qmpvntkanph760erf",
    "oauth": {
        "domain": "piggybankofhappinesscf2e2c90-cf2e2c90-develop.auth.ap-southeast-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "exp://127.0.0.1:19000/--/,exp://exp.host/@community/with-webbrowser-redirect/,exp://192.168.1.101:19000/--/,piggybankofhappiness://",
        "redirectSignOut": "exp://127.0.0.1:19000/--/,exp://exp.host/@community/with-webbrowser-redirect/,exp://192.168.1.101:19000/--/,piggybankofhappiness://",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_appsync_graphqlEndpoint": "https://xlnw64cvdzbqln35qkntfgxntu.appsync-api.ap-southeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://v7ic2jrwh2.execute-api.ap-southeast-1.amazonaws.com/develop",
            "region": "ap-southeast-1"
        }
    ],
    "aws_user_files_s3_bucket": "piggybankofhappiness5006fc9e76144bceade20e5d953101303-develop",
    "aws_user_files_s3_bucket_region": "ap-southeast-1"
};


export default awsmobile;
